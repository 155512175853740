import React from "react";


import {getCookie,setCookie} from "../variables/functions";
import {Redirect, Route, Router, Switch} from "react-router-dom";
import AdminLayout from "../layouts/Admin";
import {createBrowserHistory} from "history";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import logo from "./logo.svg"

import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Input,
    FormGroup,
    Button,
    Alert
} from "reactstrap"
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Avatar from "@material-ui/core/Avatar";
import Typography from '@material-ui/core/Typography';
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import {Copyright} from "@material-ui/icons";
import Box from "@material-ui/core/Box";

const hist = createBrowserHistory();

class Login extends React.Component{

    state = {
        login: getCookie('login'),
        password:'',
        valid:false,
        sessionId:'',
        loaded: false,
    };

    logIn =  (e) => {
        e.preventDefault();
        this.setState({loaded:false});
        fetch(`/api?function=login&login=${encodeURIComponent(this.state.login)}&password=${encodeURIComponent(this.state.password)}`)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        valid:result.valid,
                        sessionId:result.sessionid,
                        loaded:true,
                    });
                },
            )
    };



    render() {
        if (this.state.valid) {
            setCookie('sessionid',this.state.sessionId,{expires:60*60*24});
            setCookie('login',this.state.login,{expires:60*60*24});
            return (
                <Router history={hist}>
                    <Switch>
                        <Route path="/" render={(props) => <AdminLayout {...props} />} />
                        <Redirect to="/stats" />
                    </Switch>
                </Router>
            )
        }

        return (
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className="login-form bg-main">
                    <img src={logo} alt="" className={"logo-login"}/>
                    <form noValidate>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="login"
                            label="Login"
                            name="login"
                            autoComplete="login"
                            value={this.state.login}
                            onChange={e => this.setState({login:e.target.value})}
                            autoFocus
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            value={this.state.password}
                            onChange={e => this.setState({password:e.target.value})}
                            id="password"
                            autoComplete="current-password"
                        />
                    </form>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={e => this.logIn(e)}
                    >
                        Log In
                    </Button>
                </div>
            </Container>
        )
    }
}

export default Login