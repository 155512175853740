import React from "react";

import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Input, Button,
    Table
} from "reactstrap"


import Griddle, {ColumnDefinition, plugins, RowDefinition} from 'griddle-react';
import { connect } from 'react-redux';

const rowDataSelector = (state, { griddleKey }) => {
    return state
        .get('data')
        .find(rowMap => rowMap.get('griddleKey') === griddleKey)
        .toJSON();
};

const enhancedWithRowData = connect((state, props) => {
    return {
        // rowData will be available into MyCustomComponent
        rowData: rowDataSelector(state, props)
    };
});


const dateObj = new Date();
const year = dateObj.getUTCFullYear();
const yesterdayObj = new Date(dateObj.getTime() - 60 * 60 * 24 * 1000)
const tomorrowObj = new Date(dateObj.getTime() + 60 * 60 * 24 * 1000)

const yesterday = yesterdayObj.getUTCFullYear() + "-" + to2digits(yesterdayObj.getUTCMonth() + 1) + "-" + to2digits(yesterdayObj.getUTCDate())
const today = dateObj.getUTCFullYear() + "-" + to2digits(dateObj.getUTCMonth() + 1) + "-" + to2digits(dateObj.getUTCDate())
const tomorrow = tomorrowObj.getUTCFullYear() + "-" + to2digits(tomorrowObj.getUTCMonth() + 1) + "-" + to2digits(tomorrowObj.getUTCDate())

export const NewLayout = ({ Table, Pagination, Filter, SettingsWrapper }) => (
    <div>
        <Filter />
        <Table />
        <Pagination />
    </div>
);

export const styleConfig = {
    styles: {
        Filter: {
            fontSize: 18,
            margin: "10px 0 0 80%"
        },
        Pagination: {
            fontSize: 18,
            margin: "10px 0 10px 80%"
        },
        NextButton: {
            marginLeft: 10
        },
        PreviousButton: {
            marginRight: 10
        },
        Table: {
            fontSize: 18,
            margin: "10px auto",
            width: "98%"
        },
        Row : {
            borderBottom: "1px solid rgb(78, 84, 200)",
            marginBottom: 5,
            height:60,
        },
        TableHeading: {
            borderBottom: "1px solid rgb(78, 84, 200)",
        }
    }
}

function MyCustomComponent({ value, griddleKey, rowData }) {
    return (
        <div className="MyCustomComponent">
            {new Intl.NumberFormat('ru-RU').format(value)}
        </div>
    );
}


class Statistic extends React.Component{

    state = {
        dateFrom : today,
        dateTo : tomorrow,
        items: [],
        loaded:false,
        mounted : false,
        sum : {
            'reqs' : 0,
            'wins' : 0,
            'imps' : 0,
            'clicks' : 0,
            'spent' : 0,
        },
        campaign : ""

    };


    componentDidMount() {
        this.loadStat();
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (this.state.dateFrom !== prevState.dateFrom || this.state.dateTo !== prevState.dateTo) {
            this.loadStat();
        }
    }

    prevYear = () => {
        let dateFrom = (year - 1) + "-01-01"
        let dateTo = (year) + "-01-01"
        this.setState({dateFrom:dateFrom,dateTo:dateTo})
    }
    currYear = () => {
        let dateFrom = (year) + "-01-01"
        let dateTo = (year + 1) + "-01-01"
        this.setState({dateFrom:dateFrom,dateTo:dateTo})
    }
    prevMonth = () => {
        let month = dateObj.getUTCMonth()

        let yearPrev
        let monthPrev

        if (month - 1 < 0) {
            yearPrev = year - 1
            monthPrev = 12
        } else {
            monthPrev = month
        }

        let dateFrom = (yearPrev) + "-" + to2digits(monthPrev) + "-01"
        let dateTo = (year) + "-" + to2digits(month + 1) + "-01"
        this.setState({dateFrom:dateFrom,dateTo:dateTo})
    }
    currMonth = () => {
        let month = dateObj.getUTCMonth() + 1
        let dateFrom = (year) + "-" + to2digits(month) + "-01"
        let dateTo = (year) + "-" + to2digits(month + 1) + "-01"

        this.setState({dateFrom:dateFrom,dateTo:dateTo})
    }
    prevWeek = () => {
        let beforeOneWeek = new Date( new Date().getTime() - 60 * 60 * 24 * 7 * 1000),
            day = beforeOneWeek.getDay(),
            diffToMonday = beforeOneWeek.getDate() - day + (day === 0 ? -6 : 1),
            lastMonday = new Date(beforeOneWeek.setDate(diffToMonday)),
            lastSunday = new Date(beforeOneWeek.setDate(diffToMonday + 6));


        lastMonday = lastMonday.getUTCFullYear() + "-" + to2digits(lastMonday.getUTCMonth() + 1) + "-" + to2digits(lastMonday.getUTCDate())
        lastSunday = lastSunday.getUTCFullYear() + "-" + to2digits(lastSunday.getUTCMonth() + 1) + "-" + to2digits(lastSunday.getUTCDate())

        this.setState({dateFrom:lastMonday,dateTo:lastSunday})
    }
    currWeek = () => {
        let beforeOneWeek = new Date( new Date().getTime()),
            day = beforeOneWeek.getDay(),
            diffToMonday = beforeOneWeek.getDate() - day + (day === 0 ? -6 : 1),
            lastMonday = new Date(beforeOneWeek.setDate(diffToMonday)),
            lastSunday = new Date(beforeOneWeek.setDate(diffToMonday + 6));


        let monday = lastMonday.getUTCFullYear() + "-" + to2digits(lastMonday.getUTCMonth() + 1) + "-" + to2digits(lastMonday.getUTCDate())
        let sunday = lastSunday.getUTCFullYear() + "-" + to2digits(lastSunday.getUTCMonth() + 1) + "-" + to2digits(lastSunday.getUTCDate())

        this.setState({dateFrom:monday,dateTo:sunday})
    }
    yesterday = () => {

        this.setState({dateFrom:yesterday,dateTo:today})
    }
    today = () => {

        this.setState({dateFrom:today,dateTo:tomorrow})
    }

    loadStat = () => {

        console.log(this.state)

        this.setState({
            items: [],
            loaded:false,
            sum : {
                'reqs' : 0,
                'wins' : 0,
                'imps' : 0,
                'clicks' : 0,
                'spent' : 0,
            },})
        fetch(`/api?function=getStatistic&dateFrom=${this.state.dateFrom}&dateTo=${this.state.dateTo}`,)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({loaded:true,items:result});
                    result.map((row) => {
                        this.setState({sum:{
                                'reqs' : this.state.sum.reqs += row.reqs,
                                'clicks' : this.state.sum.clicks += row.clicks,
                                'wins' : this.state.sum.wins += row.wins,
                                'imps': this.state.sum.imps += row.imps,
                                'spent' : this.state.sum.spent += row.spent,
                            }});
                    })
                },
                // Примечание: Обрабатывать ошибки необходимо именно здесь
                // вместо блока catch(), чтобы не пропустить
                // исключения из реальных ошибок в компонентах.
                (error) => {
                    this.setState({loaded:true})
                }
            )
    }



    render() {

        return (
            <div className={"content"}>
                <Row>
                    <Card className={"custom-date"}>
                        <Button className={"btn-primary"} onClick={this.prevYear}>Previous year</Button>
                        <Button className={"btn-primary"} onClick={this.currYear}>Current year</Button>
                        <Button className={"btn-primary"} onClick={this.prevMonth}>Previous month</Button>
                        <Button className={"btn-primary"} onClick={this.currMonth}>Current month</Button>
                        <Button className={"btn-primary"} onClick={this.prevWeek}>Previous week</Button>
                        <Button className={"btn-primary"} onClick={this.currWeek}>Current week</Button>
                        <Button className={"btn-primary"} onClick={this.yesterday}>Yesterday</Button>
                        <Button className={"btn-primary"} onClick={this.today}>Today</Button>
                    </Card>
                </Row>
                <Row>
                    <Card className={"custom-date"}>
                        <div className={"custom-col"}>
                            <div>Date From</div>
                            <Input
                                type={"date"}
                                value={this.state.dateFrom}
                                onChange={e => this.setState({dateFrom:e.target.value})}
                            />
                        </div>
                        <div className={"custom-col"}>
                            <div>Date To</div>
                            <Input
                                type={"date"}
                                value={this.state.dateTo}
                                onChange={e => this.setState({dateTo:e.target.value})}
                            />
                        </div>

                    </Card>
                </Row>
                <Row>
                    <Card>
                        <h4 className={"dateDescription"}>
                            From: {this.state.dateFrom} To: {this.state.dateTo}
                        </h4>
                        <Griddle
                            plugins={[plugins.LocalPlugin]}
                            data={this.state.items}
                            components={{Layout:NewLayout}}
                            styleConfig={styleConfig}
                        >
                            <RowDefinition>
                                <ColumnDefinition
                                    id="name"
                                    title="Campaign Name"
                                    order={1}
                                    width={400}
                                />
                                <ColumnDefinition
                                    id="spent"
                                    title="Spent, $"
                                    cssClassName={"col-right"}
                                    headerCssClassName={"col-right"}
                                    customComponent={enhancedWithRowData(MyCustomComponent)}
                                />
                                <ColumnDefinition
                                    id="reqs"
                                    title="Num of requests"
                                    cssClassName={"col-right"}
                                    headerCssClassName={"col-right"}
                                    customComponent={enhancedWithRowData(MyCustomComponent)}
                                />
                                <ColumnDefinition
                                    id="imps"
                                    title="Num of impressions"
                                    cssClassName={"col-right"}
                                    headerCssClassName={"col-right"}
                                    customComponent={enhancedWithRowData(MyCustomComponent)}
                                />
                                <ColumnDefinition
                                    id="clicks"
                                    title="Num of clicks"
                                    cssClassName={"col-right"}
                                    headerCssClassName={"col-right"}
                                    customComponent={enhancedWithRowData(MyCustomComponent)}
                                />
                            </RowDefinition>
                        </Griddle>
                    </Card>
                </Row>

            </div>
        )
    }
}


export default Statistic

function to2digits(number) {
    if (number < 10)
        return '0' + number;

    return number
}
