import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PollIcon from '@material-ui/icons/Poll';
import SettingsIcon from '@material-ui/icons/Settings';
import HistoryIcon from '@material-ui/icons/History';
import DescriptionIcon from '@material-ui/icons/Description';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import Container from "@material-ui/core/Container";
import {Navbar} from "reactstrap";

import logo from "./C2P.png"
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    mainMenu: {
        width: '100%',
        maxWidth: 600,
        display: "flex",
    },
    nested1: {
        position: 'absolute',
        top: 60,
        right: 0,
        left: 0,
        zIndex: 1,
    },
    nested2: {
        position: 'absolute',
        top: 60,
        right: 0,
        left: 200,
        zIndex: 1,
    },
    nested3: {
        position: 'absolute',
        top: 60,
        right: 0,
        left: 400,
        zIndex: 1,
    },
}));

export default function NestedList() {
    const classes = useStyles();
    const [open1, setOpen1] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [open3, setOpen3] = React.useState(false);

    const handleClick1 = () => {
        setOpen1(!open1)
    }

    const handleClick2 = () => {
        setOpen2(!open2)
    }

    const handleClick3 = () => {
        setOpen3(!open3)
    }


    return (
        <div className="purple-header">
            <Navbar
                expand="lg"
                className={"navbar-absolute fixed-top pl-lg-5 pr-lg-5"}
            >
                <Container fluid className={"main-menu"}>
                    <img src={logo} alt=""/>
                    <List
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                        className={classes.mainMenu}
                    >
                        <ListItem button onClick={handleClick1}>
                            <ListItemIcon>
                                <AttachMoneyIcon />
                            </ListItemIcon>
                            <ListItemText primary="Finance" />
                            {open1 ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={open1} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding className={classes.nested1}>
                                <Link to="history">
                                    <ListItem button onClick={handleClick1}>
                                        <ListItemIcon>
                                            <HistoryIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Payments history" />
                                    </ListItem>
                                </Link>
                                <Link to="/docs">
                                    <ListItem button onClick={handleClick1}>
                                        <ListItemIcon>
                                            <DescriptionIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Documents" />
                                    </ListItem>
                                </Link>
                            </List>
                        </Collapse>
                        <ListItem button onClick={handleClick2}>
                            <ListItemIcon>
                                <PollIcon />
                            </ListItemIcon>
                            <ListItemText primary="Statistics" />
                            {open2 ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={open2} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding className={classes.nested2}>
                                <Link to="/stats">
                                    <ListItem button onClick={handleClick2}>
                                        <ListItemIcon>
                                            <EqualizerIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Campaigns" />
                                    </ListItem>
                                </Link>
                            </List>
                        </Collapse>
                        <ListItem button onClick={handleClick3}>
                            <ListItemIcon>
                                <SettingsIcon />
                            </ListItemIcon>
                            <ListItemText primary="Settings" />
                            {open3 ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={open3} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding className={classes.nested3}>
                                <Link to="logout">
                                <ListItem button>
                                    <ListItemIcon>
                                        <ExitToAppIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Log out" />
                                </ListItem>
                                </Link>
                            </List>
                        </Collapse>
                    </List>
                </Container>
            </Navbar>
        </div>

    );
}